import React, {useEffect, useState} from 'react';
import Login from './Login';
import Register from './Register';
import HomePage from './Home'; // You need to create this component
import { Button, Box } from '@mui/material';

function App() {
    const [showLogin, setShowLogin] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        // On app load, check if a token exists in local storage
        const token = localStorage.getItem('token');
        if (token) {
            // TODO: Validate the token to ensure it's still valid.
            // You could make an API call to your backend to verify the token.
            // For example:
            /*
            fetch('/api/verifyToken', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then(response => {
                if (response.ok) {
                    setIsLoggedIn(true);
                }
            })
            .catch(error => {
                console.error('Token verification failed:', error);
            });
            */
            setIsLoggedIn(true); // Remove this line if you're doing the actual token validation
        }
    }, []);

    if (isLoggedIn) {
        // If the user is logged in, render the HomePage component
        return <HomePage setIsLoggedIn={setIsLoggedIn}/>;
    }

    return (
        <div className="App">
            {showLogin ? (
                <>
                    <Login setIsLoggedIn={setIsLoggedIn} /> {/* Pass the setIsLoggedIn function to Login */}
                    <Box display="flex" justifyContent="center" mt={2}>
                        <Button onClick={() => setShowLogin(false)}>Don't have an account? Register</Button>
                    </Box>
                </>
            ) : (
                <>
                    <Register />
                    <Box display="flex" justifyContent="center" mt={2}>
                        <Button onClick={() => setShowLogin(true)}>Already have an account? Login</Button>
                    </Box>
                </>
            )}
        </div>
    );
}

export default App;
