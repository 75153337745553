// src/HomePage.js
import React, {useState, useEffect} from 'react';
import ProductCard from './ProductCard';
// Place this with other Material-UI imports at the top of your HomePage.js
import { TextField, MenuItem, Select, FormControl, InputLabel, ListSubheader } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';


import {
    AppBar,
    Toolbar,
    Typography,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Box,
    Card,
    CardContent,
    Button,
    Divider
} from '@mui/material';
import ProductCardDetails from "./ProductCardDetails";

const drawerWidth = 240;
const appBarHeight = 64; // Adjust this value if your AppBar height is different

const HomePage = ({setIsLoggedIn}) => {
    const apiBaseUrl = process.env.REACT_APP_API_URL;
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [products, setProducts] = useState([]); // Add this line
    const [keyword, setKeyword] = useState('');
    const [category, setCategory] = useState('');
    const [subscriptions, setSubscriptions] = useState([]);
    const [activeSubscriptionId, setActiveSubscriptionId] = useState(null);

    useEffect(() => {


        fetchProducts();
    }, []); // The empty array means this effect runs once when the component mounts

    useEffect(() => {


        fetchSubscriptions();
    }, []);

    // Dummy data for products
    // const products = [
    //     { id: 1, title: "Product 1", description: "Description 1" },
    //     // ... more products
    // ];

    const handleProductClick = (product) => {
        setSelectedProduct(product);
    };

    const handleLogout = () => {
        // Remove the token from local storage
        localStorage.removeItem('token');
        // Perform logout operations like clearing the token from storage
        console.log("User logged out");
        setIsLoggedIn(false);
        // Redirect to login page or update state to show logged out status
    };

    const fetchSubscriptions = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${apiBaseUrl}/api/Subscription`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch subscriptions');
            }
            const data = await response.json();
            setSubscriptions(data);
        } catch (error) {
            console.error('There was an error fetching the subscriptions:', error);
        }
    };

    const fetchProducts = async () => {
        const token = localStorage.getItem('token'); // Assuming the token is stored with this key
        try {
            const response = await fetch(`${apiBaseUrl}/api/products`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch products');
            }
            const data = await response.json();
            setProducts(data); // Assuming the JSON response is the array of products
        } catch (error) {
            console.error('There was an error fetching the products:', error);
            // Handle the error, e.g., show an error message to the user
        }
    };

    const handleDeleteSubscription = async (subscriptionId) => {
        const token = localStorage.getItem('token'); // Assuming the token is stored with this key
        try {
            const response = await fetch(`${apiBaseUrl}/api/Subscription/${subscriptionId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to delete the subscription');
            }

            // If delete was successful, remove the subscription from the state
            setSubscriptions(subscriptions.filter((sub) => sub.id !== subscriptionId));
        } catch (error) {
            console.error('There was an error deleting the subscription:', error);
            // Handle the error, e.g., show an error message to the user
        }
    };

    const handleSubscribe = async () => {
        const token = localStorage.getItem('token');
        const subscriptionData = {
            keyword: keyword,
            category: category,
        };

        try {
            const response = await fetch(`${apiBaseUrl}/api/Subscription`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(subscriptionData),
            });

            if (!response.ok) {
                throw new Error('Failed to create subscription');
            }

            const newSubscription = await response.json();
            setSubscriptions([...subscriptions, newSubscription]); // Add the new subscription to the state
            setKeyword(''); // Optionally clear the keyword input
            setCategory(''); // Optionally clear the category input
        } catch (error) {
            console.error('There was an error creating the subscription:', error);
        }
    };

    const fetchProductsByKeyword = async (keyword) => {
        const token = localStorage.getItem('token');
        try {
            // If a keyword is provided, append it as a query parameter
            const queryParam = keyword ? `?keyword=${encodeURIComponent(keyword)}` : '';
            const response = await fetch(`${apiBaseUrl}/ProductsBySubscription${queryParam}`, {
                method: 'POST', // This should be 'GET' if your endpoint only requires GET
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch products');
            }
            const data = await response.json();
            setProducts(data);
        } catch (error) {
            console.error('There was an error fetching the products:', error);
        }
    };

    const handleSubscriptionClick = (subscription) => {
        // Use the keyword from the subscription to fetch products
        fetchProductsByKeyword(subscription.keyword);
        // Set the active subscription ID
        setActiveSubscriptionId(subscription.id);
    };


    const refreshData = () => {
        // fetchProducts();
        setProducts([]);
        setActiveSubscriptionId(null);
        fetchSubscriptions();
    };

    return (
        <Box sx={{display: 'flex', height: '100vh'}}>
            <AppBar position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
                <Toolbar>
                    <Typography variant="h6" noWrap component="div" sx={{flexGrow: 1}}>
                        Voithos
                    </Typography>
                    <IconButton color="inherit" onClick={refreshData}>
                        <RefreshIcon />
                    </IconButton>
                    <Button color="inherit" onClick={handleLogout}>Logout</Button>
                </Toolbar>
            </AppBar>

            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto', p: 2 }}>
                    <TextField
                        label="Keyword"
                        variant="outlined"
                        fullWidth
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                        margin="normal"
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="category-select-label">Category</InputLabel>
                        <Select
                            labelId="category-select-label"
                            id="category-select"
                            value={category}
                            label="Category"
                            onChange={(e) => setCategory(e.target.value)}
                        >
                            <MenuItem value={0}>Any</MenuItem>
                            <ListSubheader>Technics</ListSubheader>
                            <MenuItem value={1064}>All Technics</MenuItem>
                            <MenuItem value={56}>PC Parts</MenuItem>
                            <MenuItem value={123}>Monitors</MenuItem>
                            <MenuItem value={53}>Laptops</MenuItem>
                            <MenuItem value={69}>Phones</MenuItem>

                            <ListSubheader>Music</ListSubheader>
                            <MenuItem value={1068}>All Music</MenuItem>
                            <MenuItem value={544}>Drums</MenuItem>
                            <MenuItem value={99}>Keyboards</MenuItem>
                        </Select>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubscribe}
                            sx={{ mt: 2 }} // Add margin top for spacing
                        >
                            Subscribe
                        </Button>
                    </FormControl>
                    {/* Render subscriptions here */}
                    {subscriptions.map((subscription) => (
                        <Box key={subscription.id} sx={{ display: 'flex', alignItems: 'center', mb: 0 }}>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    flexGrow: 1,
                                    cursor: 'pointer',
                                    // Add border and padding for better visual
                                    border: (theme) => `1px solid ${theme.palette.divider}`,
                                    borderRadius: 1, // Rounded corners
                                    p: 1, // Padding inside the items
                                    my: 0.5, // Margin vertically for space between items
                                    bgcolor: subscription.id === activeSubscriptionId ? 'action.selected' : 'transparent',
                                    '&:hover': {
                                        bgcolor: subscription.id === activeSubscriptionId ? 'action.selected' : 'action.hover',
                                    },
                                    // Align text and icon vertically
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                                onClick={() => handleSubscriptionClick(subscription)}
                            >
                                {subscription.keyword}
                                <IconButton aria-label="delete" onClick={() => handleDeleteSubscription(subscription.id)} size="small">
                                    <DeleteIcon fontSize="inherit" />
                                </IconButton>
                            </Typography>
                        </Box>
                    ))}

                </Box>

            </Drawer>


            {/* Main content with padding-top equal to AppBar height */}
            <Box component="main" sx={{flexGrow: 1, p: 3, pt: `${appBarHeight}px`}}>
                {/*<Typography paragraph>*/}
                {/*    Here are your products:*/}
                {/*</Typography>*/}
                <Box sx={{display: 'grid', gap: 0, gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))'}}>
                    {products.map((product) => (
                        <ProductCard key={product.productId} product={product} onProductClick={handleProductClick}/>
                    ))}
                </Box>
            </Box>

            {/* Product details with divider */}
            <Divider orientation="vertical" flexItem/>
            {/*<Box component="aside" sx={{*/}
            {/*    height: `calc(100vh - ${appBarHeight}px)`,*/}
            {/*    // position: 'sticky',*/}
            {/*    // top: `${appBarHeight}px`,*/}
            {/*    position: "relative",*/}
            {/*    mt: 5,*/}
            {/*    p: 3,*/}
            {/*    width: '20%',*/}
            {/*    minWidth: '250px',*/}
            {/*    overflowY: 'auto' // If the content is too long, it will scroll inside the box*/}
            {/*}}>*/}
            {/*    <Typography variant="h6">Product Details:</Typography>*/}
            {/*    {selectedProduct ? (*/}
            {/*        <Box>*/}
            {/*            <ProductCardDetails product={selectedProduct} onProductClick={() => console.log("Product selected")}/>*/}
            {/*            /!*<Typography variant="h5">{selectedProduct.title}</Typography>*!/*/}
            {/*            /!*<Typography color="text.secondary">{selectedProduct.description}</Typography>*!/*/}
            {/*            /!* Add more details here *!/*/}
            {/*        </Box>*/}
            {/*    ) : (*/}
            {/*        <Typography>Select a product to see details.</Typography>*/}
            {/*    )}*/}
            {/*</Box>*/}

            <Box sx={{ width: '20%', minWidth: '500px', position: 'sticky', top: `${appBarHeight}px`, alignSelf: 'flex-start' }}>
                <Typography variant="h6">Product Details:</Typography>
                {selectedProduct ? (
                    <ProductCardDetails product={selectedProduct} products={products} onProductClick={() => console.log("Product selected")}/>
                ) : (
                    <Typography>Select a product to see details.</Typography>
                )}


            </Box>

            {/*<Box component="aside" sx={{ flexGrow: 0, p: 3, pt: `${appBarHeight}px`, width: '20%', minWidth: '250px' }}>*/}
            {/*    <Typography variant="h6">Product Details:</Typography>*/}
            {/*    {selectedProduct ? (*/}
            {/*        <Box>*/}
            {/*            <Typography variant="h5">{selectedProduct.title}</Typography>*/}
            {/*            <Typography color="text.secondary">{selectedProduct.description}</Typography>*/}
            {/*            /!* Add more details here *!/*/}
            {/*        </Box>*/}
            {/*    ) : (*/}
            {/*        <Typography>Select a product to see details.</Typography>*/}
            {/*    )}*/}
            {/*</Box>*/}
        </Box>
    );
};

export default HomePage;
