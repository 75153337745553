import React from 'react';
import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    CardActions,
    IconButton,
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';

const ProductCard = ({ product, onProductClick }) => {
    return (
        <Card sx={{ maxWidth: 345, m: 1, '&:hover': { cursor: 'pointer' } }} onClick={() => onProductClick(product)}>
            <CardMedia
                component="img"
                height="150"
                image={product.photos[0]} // Assuming the first photo is the one you want to display
                alt={product.title}
            />
            <CardContent>
                <Typography gutterBottom variant="h7" component="div">
                    {product.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'GEL', // Replace with the appropriate currency code
                    }).format(product.price)}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                {/*<IconButton aria-label="add to favorites">*/}
                {/*    /!*<FavoriteIcon />*!/*/}
                {/*</IconButton>*/}
            </CardActions>
        </Card>
    );
};

export default ProductCard;
