// src/Register.js
import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box, Alert } from '@mui/material';

const Register = () => {
    const apiBaseUrl = process.env.REACT_APP_API_URL;
    const [registerError, setRegisterError] = useState('');
    const [registerSuccess, setRegisterSuccess] = useState('');

    const handleRegister = async (event) => {
        event.preventDefault();
        setRegisterError('');
        setRegisterSuccess('');
        const data = new FormData(event.currentTarget);
        const registerDetails = {
            email: data.get('email'),
            password: data.get('password'),
        };

        try {
            const response = await fetch(`${apiBaseUrl}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(registerDetails),
            });

            // Check if the response is OK and has content
            if (response.ok) {
                try {
                    const responseData = await response.json(); // Attempt to parse JSON
                    console.log('Registration successful:', responseData);
                    setRegisterSuccess('Registration successful! You can now log in.');
                } catch (jsonError) {
                    // Handle the case where the response does not contain JSON
                    console.log('Registration successful, but no JSON response:', jsonError);
                    setRegisterSuccess('Registration successful! You can now log in.');
                }
            } else {
                // Handle HTTP errors
                try {
                    const errorData = await response.json(); // Attempt to parse JSON for error details
                    setRegisterError(errorData.message || 'Registration failed');
                } catch (jsonError) {
                    setRegisterError('Registration failed: Invalid server response');
                }
            }
        } catch (error) {
            console.error('There was an error during registration:', error);
            setRegisterError(error.message || 'An error occurred during registration.');
        }
    };


    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Register
                </Typography>
                {registerError && <Alert severity="error" sx={{ width: '100%', mt: 2 }}>{registerError}</Alert>}
                {registerSuccess && <Alert severity="success" sx={{ width: '100%', mt: 2 }}>{registerSuccess}</Alert>}
                <Box component="form" onSubmit={handleRegister} noValidate sx={{ mt: 1 }}>
                                         <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            autoComplete="email"
                                            autoFocus
                                        />
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type="password"
                                            id="password"
                                            autoComplete="new-password"
                                        />
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                        >
                                            Register
                                        </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default Register;


// // src/Register.js
// import React from 'react';
// import { Container, Typography, TextField, Button, Box } from '@mui/material';
//
// const Register = () => {
//     const apiBaseUrl = process.env.REACT_APP_API_URL;
//     const handleRegister = async (event) => {
//         event.preventDefault();
//         const data = new FormData(event.currentTarget);
//         const registerDetails = {
//             email: data.get('email'),
//             password: data.get('password'),
//         };
//         // Here you would call your backend API to perform the registration action
//         try {
//             const response = await fetch(`${apiBaseUrl}/register`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(registerDetails),
//             });
//             const responseData = await response.json();
//             if (response.ok) {
//                 console.log('Registration successful:', responseData);
//
//                 // You can handle post-registration logic here, like redirecting to the login page or directly logging in the user
//             } else {
//                 console.error('Registration failed:', responseData);
//                 // Handle errors, for example, show an error message to the user
//             }
//         } catch (error) {
//             console.error('There was an error during registration:', error);
//         }
//     };
//
//     return (
//         <Container component="main" maxWidth="xs">
//             <Box
//                 sx={{
//                     marginTop: 8,
//                     display: 'flex',
//                     flexDirection: 'column',
//                     alignItems: 'center',
//                 }}
//             >
//                 <Typography component="h1" variant="h5">
//                     Register
//                 </Typography>
//                 <Box component="form" onSubmit={handleRegister} noValidate sx={{ mt: 1 }}>
//                     <TextField
//                         margin="normal"
//                         required
//                         fullWidth
//                         id="email"
//                         label="Email Address"
//                         name="email"
//                         autoComplete="email"
//                         autoFocus
//                     />
//                     <TextField
//                         margin="normal"
//                         required
//                         fullWidth
//                         name="password"
//                         label="Password"
//                         type="password"
//                         id="password"
//                         autoComplete="new-password"
//                     />
//                     <Button
//                         type="submit"
//                         fullWidth
//                         variant="contained"
//                         sx={{ mt: 3, mb: 2 }}
//                     >
//                         Register
//                     </Button>
//                 </Box>
//             </Box>
//         </Container>
//     );
// };
//
// export default Register;
