// src/ProductCard.js
import React, { useState } from 'react';
import {
    Card,
    CardContent,
    Button,
    Typography,
    MobileStepper,
    Paper, Box
} from '@mui/material';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; // Import the icon


const ProductCardDetails = ({ product, products, onProductClick }) => {
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = product.photos.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };



    // const chartData = {
    //     labels: products.map((_, index) => `Product ${index + 1}`), // Generic labels for each product
    //     datasets: [{
    //         label: 'Product Prices',
    //         data: products.map(p => p.price),
    //         backgroundColor: products.map(p =>
    //             p.id === product.id ? 'rgba(255, 99, 132, 0.5)' : 'rgba(0, 123, 255, 0.5)'
    //         ),
    //         borderColor: products.map(p =>
    //             p.id === product.id ? 'rgba(255, 99, 132, 1)' : 'rgba(0, 123, 255, 1)'
    //         ),
    //         borderWidth: 1,
    //     }]
    // };

    const chartData = {
        labels: products.map((_, index) => `Product ${index + 1}`), // Generic labels for each product
        datasets: [{
            label: 'Product Prices',
            data: products.map(p => p.price),
            backgroundColor: products.map(p =>
                p.id === product.id ? 'rgba(75, 192, 192, 0.5)' : 'rgba(255, 99, 132, 0.5)'
            ),
            borderColor: products.map(p =>
                p.id === product.id ? 'rgba(75, 192, 192, 1)' : 'rgba(255, 99, 132, 1)'
            ),
            borderWidth: 1,
        }]
    };


    const chartOptions = {
        scales: {
            y: {
                beginAtZero: true
            },
            x: {
                // This will prevent the chart from showing the x-axis labels if they are not needed
                display: false
            }
        },
        plugins: {
            legend: {
                display: true // Set this to false if you want to hide the legend
            },
            tooltip: {
                callbacks: {
                    // This callback makes the tooltip show the actual price instead of "Product X"
                    title: function(tooltipItems) {
                        return products[tooltipItems[0].dataIndex].name;
                    },
                    label: function(tooltipItem) {
                        return `Price: ${tooltipItem.formattedValue}`;
                    }
                }
            }
        }
    };



    return (
        <Box sx={{ maxWidth: 345, margin: 'auto', position: "sticky", top: 0 }}>

            <img
                src={product.photos[activeStep]}
                alt={product.title}
                style={{ width: '100%', height: 255, overflow: 'hidden', display: 'block' }}
            />
            <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                        Next
                        <KeyboardArrowRight />
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        <KeyboardArrowLeft />
                        Back
                    </Button>
                }
            />
            <CardContent>
                <Paper square elevation={0} sx={{ display: 'flex', alignItems: 'center', height: 50, pl: 2, bgcolor: 'background.default' }}>
                    <Typography>{product.title}</Typography>
                </Paper>
                <Typography variant="body2" color="text.secondary">
                    Price: {product.price}
                    Id: {product.productId}
                </Typography>
                {/*<Button size="small" onClick={() => window.open(`https://www.mymarket.ge/ka/pr/${product.id}`, '_blank')}>Go to Product</Button>*/}
                <Button
                    size="small"
                    onClick={() => window.open(`https://www.mymarket.ge/ka/pr/${product.productId}`, '_blank')}
                    variant="contained" // This gives the button a background color
                    endIcon={<ArrowForwardIcon />} // This adds an arrow icon to the end of the button text
                    sx={{
                        width: '100%', // Makes the button longer by setting the width to 100% of its parent container
                        justifyContent: 'space-between' // Puts space between the button label and the icon
                    }}
                >
                    See on Website
                </Button>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                    {product.descr}

                </Typography>
            </CardContent>

                {/* Chart */}
                <Box sx={{ marginTop: 2 }}>
                    <Bar data={chartData} options={chartOptions} />
                </Box>



        </Box>
    );
};

export default ProductCardDetails;
