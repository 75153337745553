// src/Login.js
import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box, Alert } from '@mui/material';

const Login = ({ setIsLoggedIn }) => {
    const apiBaseUrl = process.env.REACT_APP_API_URL;
    const [loginError, setLoginError] = useState('');
    const [loginSuccess, setLoginSuccess] = useState('');

    const handleLogin = async (event) => {
        event.preventDefault();
        setLoginError(''); // Reset error message
        setLoginSuccess(''); // Reset success message
        const data = new FormData(event.currentTarget);
        const loginDetails = {
            email: data.get('email'),
            password: data.get('password'),
        };

        try {
            const response = await fetch(`${apiBaseUrl}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginDetails),
            });

            const responseData = await response.json();
            if (response.ok) {
                console.log('Login successful:', responseData);
                localStorage.setItem('token', responseData.accessToken); // Store the token
                setIsLoggedIn(true);
                setLoginSuccess('Login successful! Redirecting...'); // Set success message
            } else {
                console.error('Login failed:', responseData);
                setLoginError(responseData.message || 'Login failed'); // Set error message
            }
        } catch (error) {
            console.error('There was an error during the login:', error);
            setLoginError(error.message || 'An error occurred during login.'); // Set error message
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                {loginError && <Alert severity="error" sx={{ width: '100%', mt: 2 }}>{loginError}</Alert>}
                {loginSuccess && <Alert severity="success" sx={{ width: '100%', mt: 2 }}>{loginSuccess}</Alert>}
                <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default Login;




// // src/Login.js
// import React from 'react';
// import { Container, Typography, TextField, Button, Box } from '@mui/material';
//
// const Login = ({ setIsLoggedIn }) => {
//     const apiBaseUrl = process.env.REACT_APP_API_URL;
//     const handleLogin = async (event) => {
//         event.preventDefault();
//         const data = new FormData(event.currentTarget);
//         const loginDetails = {
//             email: data.get('email'),
//             password: data.get('password'),
//         };
//
//         try {
//             const response = await fetch(`${apiBaseUrl}/login`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(loginDetails),
//             });
//
//             const responseData = await response.json();
//             if (response.ok) {
//                 console.log('Login successful:', responseData);
//                 localStorage.setItem('token', responseData.accessToken); // Store the token
//                 setIsLoggedIn(true);
//             } else {
//                 console.error('Login failed:', responseData);
//                 // Handle errors, for example, show an error message to the user
//             }
//         } catch (error) {
//             console.error('There was an error during the login:', error);
//             // Handle errors, for example, show an error message to the user
//         }
//     };
//
//     return (
//         <Container component="main" maxWidth="xs">
//             <Box
//                 sx={{
//                     marginTop: 8,
//                     display: 'flex',
//                     flexDirection: 'column',
//                     alignItems: 'center',
//                 }}
//             >
//                 <Typography component="h1" variant="h5">
//                     Sign in
//                 </Typography>
//                 <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
//                     <TextField
//                         margin="normal"
//                         required
//                         fullWidth
//                         id="email"
//                         label="Email Address"
//                         name="email"
//                         autoComplete="email"
//                         autoFocus
//                     />
//                     <TextField
//                         margin="normal"
//                         required
//                         fullWidth
//                         name="password"
//                         label="Password"
//                         type="password"
//                         id="password"
//                         autoComplete="current-password"
//                     />
//                     <Button
//                         type="submit"
//                         fullWidth
//                         variant="contained"
//                         sx={{ mt: 3, mb: 2 }}
//                     >
//                         Sign In
//                     </Button>
//                 </Box>
//             </Box>
//         </Container>
//     );
// };
//
// export default Login;
